import { loadStripe } from '@stripe/stripe-js/pure';
import up from 'unpoly';

const elId = (suffix) => `#view-templates-PageCheckoutStripe-${suffix}`;

up.compiler(elId('form'), async (form) => {
    const { publishableKey, clientSecret, cardRequiredError } = form.dataset;
    const billingDetails = JSON.parse(form.dataset.billingDetails || 'null');

    const getEl = (suffix) => form.querySelector(elId(suffix));

    const fieldset = getEl('fieldset');
    const editInputButton = getEl('edit-input');
    const completePaymentButton = getEl('complete_payment');
    const cardContainer = getEl('card-control');
    const cardError = getEl('card-control-error');
    const paymentMethodInput = form.querySelector('[name="payment_method"]');

    let card = null;

    let submitter = null;

    // Submit Handler

    let submit = async () => {
        try {
            await up.submit(form);
        } catch (err) {} // eslint-disable-line no-empty
    };
    let submitting = false;
    const setSubmitting = (v) => {
        submitting = v;

        fieldset.disabled = submitting;
        card?.update({ disabled: submitting });
    };

    editInputButton?.addEventListener('click', (event) => {
        submitter = event.target;
    });
    completePaymentButton?.addEventListener('click', (event) => {
        submitter = event.target;
    });

    form.addEventListener('submit', (event) => {
        event.preventDefault();

        const isEditInput =
            editInputButton &&
            (document.activeElement === editInputButton ||
                submitter === editInputButton);
        submitter = null;

        if (submitting) {
            return;
        }

        setSubmitting(true);

        if (isEditInput) {
            up.reload(form);

            return;
        }

        submit()
            .catch((error) => {
                // eslint-disable-next-line no-alert
                alert(error.message ?? 'An unexpected error occurred.');
            })
            .then(() => {
                setSubmitting(false);
            });
    });

    window.addEventListener('app:reload', () => {
        window.location.reload();
    });

    const stripe = await loadStripe(publishableKey, {
        apiVersion: '2020-08-27',
    });

    if (cardContainer) {
        const elements = stripe.elements({
            // fonts: [
            //     {
            //         cssSrc: '/build/fonts.css',
            //     },
            // ],
        });

        const style = {
            base: {
                fontSize: '16px',
                // The vertical alignment is off when using this.
                //
                // fontFamily:
                //     'Aeonik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
                lineHeight: '1.15',
            },
        };

        card = elements.create('card', { hidePostalCode: true, style });

        let cardIsComplete = false;

        card.on('ready', () => {
            card.focus();
        });

        card.on('change', (event) => {
            cardError.textContent = event.error?.message;
            cardIsComplete = event.complete;
        });

        card.on('blur', () => {
            if (cardIsComplete || cardError.textContent) {
                return;
            }

            cardError.textContent = cardRequiredError;
        });

        card.mount(cardContainer);

        submit = async () => {
            if (!cardIsComplete) {
                if (!cardError.textContent) {
                    cardError.textContent = cardRequiredError;
                }

                return;
            }

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card,
                billing_details: billingDetails,
            });

            if (result.error) {
                cardError.textContent = result.error.message;

                return;
            }

            paymentMethodInput.value = result.paymentMethod.id;

            await up.submit(form);
        };

        return () => {
            card.destroy();
        };
    }

    if (!clientSecret) {
        return () => {};
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    if (!paymentIntent) {
        up.reload(form);

        return () => {};
    }

    if (paymentIntent.status === 'requires_action') {
        const result = await stripe.handleCardAction(clientSecret);

        if (result.error) {
            // eslint-disable-next-line no-alert
            alert(result.error.message);

            return () => {};
        }

        up.reload(form);
    }

    return () => {};
});
